import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useDialog, useSpinner } from "@core/hooks"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_history_query, match_infos_list_query, match_offer_detail_query, match_offers_query, MAX_KM_DISTANCE, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { customRound, formatDurationMin, frequencyToText, joinOu } from "@core/utils/format"
import { goBack } from "@core/utils/navigation/go-back"
import { conditional, txtmap } from "@core/utils/textUtils"
import { notNil } from "@core/utils/tools"
import { AntDesign, FontAwesome5 } from "@expo/vector-icons"
import type { NavigationProp, ParamListBase } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { useQueryClient } from "@tanstack/react-query"
import { capitalize, isNil } from "lodash"
import { DateTime } from "luxon"
import { Alert, Badge, Button, Center, CheckCircleIcon, Divider, Heading, HStack, Icon, Modal, ScrollView, Spacer, Text, useColorModeValue, VStack } from "native-base"
import React, { type ReactNode } from "react"

import type { MainStackParamsList } from "../MainStack"
import { formatISOTime, getDayFromNumber, groupWeekDisposByDay } from "../user-screen/WeekDispoScreenComponentAndUtils/utils"

export function OfferDetailScreen(props: StackScreenProps<MainStackParamsList, "OfferDetail">) {
    const {
        navigation,
        route: { params },
    } = props
    const { matchId } = params

    const auth = useAuthContext()

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const { matchOfferDetailQuery } = match_offer_detail_query.use({ matchId, teacher: userInfoQuery.data })

    const qc = useQueryClient()

    const spinner = useSpinner()
    const handleAccept = useCatchToBoundary(async () => {
        try {
            spinner.start()
            await auth.ensureValidToken()
            await openapi.propositions.manifestMatchInterest({ matchId: offer!.id, teacherId: auth.userId! })
            qc.invalidateQueries({ queryKey: match_offers_query.getKey({ teacher: userInfoQuery.data }) })
            navigation.navigate("MainTabs", { screen: "Offres" })
        } finally {
            spinner.stop()
        }
    })

    const handleDismiss = useCatchToBoundary(async () => {
        try {
            spinner.start()
            await auth.ensureValidToken()
            await openapi.propositions.dismissMatchInterest({ matchId: offer!.id, teacherId: auth.userId! })
            qc.invalidateQueries({ queryKey: match_offers_query.getKey({ teacher: userInfoQuery.data }) })
            navigation.navigate("MainTabs", { screen: "Offres" })
        } finally {
            spinner.stop()
        }
    })

    const parrainageDialog = useDialog()
    if (matchOfferDetailQuery.isLoading) return <LoadingScreen />

    const offer = matchOfferDetailQuery.data
    const unavailable = offer === null

    const showDistance = !!offer && offer?.shortestDistanceKm !== null && offer.shortestDistanceKm <= MAX_KM_DISTANCE && offer.wishVisio !== "Visio"
    const showCities = offer?.wishVisio !== "Visio" && (offer?.urgent || showDistance)

    // const proximityAddresses = offer?.lessonAddressesPlus.filter(addr => addr.shortestDistanceKm !== null && addr.shortestDistanceKm <= MAX_KM_DISTANCE)

    let citiesText = ""
    if (showCities) {
        citiesText = joinOu(offer?.lessonAddressesPlus.map((addr) => addr.cityName))

        if (showDistance) {
            citiesText += ` (${customRound(offer.shortestDistanceKm, "0.1")}km)`
        }
    }

    let modeText = ""
    if (offer?.urgent || showDistance) {
        modeText = txtmap(offer!.wishVisio, { Visio: "En visio", Domicile: `À domicile`, "Les deux": `À domicile ou en visio` })
    } else {
        //Si la distance min est >= 30km, on dit toujours en visio
        modeText = "En visio"
    }
    // const proximityAddresses = addressesPlus.filter(addr => addr.shortestDistanceKm !== null && addr.shortestDistanceKm <= MAX_KM_DISTANCE)
    // const cities = uniq(proximityAddresses.map(addr => `${addr.cityName}`))

    return (
        <VStack flexGrow={1} alignItems="stretch">
            <AppBar onBack={() => goBack(navigation, "MainTabs", { screen: "Offres" })}>
                <Heading flex={1} fontSize="lg">
                    Offre de cours
                </Heading>
            </AppBar>

            {unavailable ? (
                <Center flexGrow={1}>
                    <Text fontSize="xl">Offre non disponible</Text>
                </Center>
            ) : (
                <ScrollView style={{ width: "100%", height: 0 }} contentContainerStyle={{ flexGrow: 1, width: "100%" }}>
                    {/* <Container flexGrow={1} alignItems={"stretch"} alignSelf="center" margin="20px"> */}
                    <ContentContainer py="15px" height="full" space={4}>
                        <HStack space={2} justifyContent="space-between">
                            <Heading>{offer?.label}</Heading>
                            {offer?.urgent && (
                                <Center>
                                    <Badge variant="subtle" colorScheme="yellow" rounded="full" startIcon={<FontAwesome5 name="gift" />}>
                                        Parrainage 10€
                                    </Badge>
                                </Center>
                            )}
                        </HStack>

                        <VStack>
                            <DetailSection noDivider title="Type de cours" hide={!modeText}>
                                <Text>{modeText}</Text>
                            </DetailSection>

                            <DetailSection title="Lieu" hide={!citiesText}>
                                <Text>{citiesText}</Text>
                                <Text fontSize="xs">Nous te communiquerons l'adresse exacte dans un second temps</Text>
                            </DetailSection>

                            <DetailSection title="Objectifs" hide={!offer?.Program?.goals}>
                                <Text>{offer?.Program?.goals}</Text>
                            </DetailSection>

                            <DetailSection title="Moyenne" hide={!offer?.Program?.initialMean}>
                                <Text>{offer?.Program?.initialMean?.toFixed(0)}</Text>
                            </DetailSection>

                            {offer?.Program?.wishedDurationMin && offer?.Program?.wishedFrequency && (
                                <DetailSection title="Durée et fréquence souhaitée (à titre indicatif)" hide={!offer?.Program?.wishedDurationMin || !offer?.Program?.wishedFrequency}>
                                    <Text>
                                        {formatDurationMin(offer?.Program?.wishedDurationMin ?? 0)} {!isNil(offer?.Program.wishedFrequency) && frequencyToText(offer?.Program.wishedFrequency ?? 0)}
                                    </Text>
                                </DetailSection>
                            )}

                            <DetailSection title="Dispos (à titre indicatif)">
                                {offer?.Program?.Learner.WeekDispos.length ? (
                                    groupWeekDisposByDay(offer?.Program?.Learner.WeekDispos).map((dayItem) => (
                                        <Text key={dayItem.dayId}>
                                            {getDayFromNumber(dayItem.dayId)} {dayItem.dayDispos.map((disp) => formatISOTime(disp.startTime) + "-" + formatISOTime(disp.endTime)).join(", ")}
                                        </Text>
                                    ))
                                ) : (
                                    <Text>Horaires à définir</Text>
                                )}
                            </DetailSection>

                            <DetailSection title="Commentaires" hide={!offer?.Program.defaultTeacherInstructions}>
                                <Text>{offer?.Program.defaultTeacherInstructions}</Text>
                            </DetailSection>
                        </VStack>

                        <HStack alignItems="center" justifyContent="end" space={5}>
                            {offer?.urgent && (
                                <Button
                                    onPress={() => {
                                        parrainageDialog.open()
                                    }}
                                    variant="solid"
                                    size="sm"
                                    colorScheme="primary"
                                    startIcon={<Icon as={FontAwesome5} name="gift" />}
                                >
                                    Parrainer un ami
                                </Button>
                            )}
                            <Spacer />

                            {offer?.interestAnswer === "INTERESTED" ? (
                                <HStack space={2}>
                                    <Text fontSize="sm">
                                        <Icon as={AntDesign} name="star" size="20px" color="yellow.500" _dark={{ color: "yellow.500" }} /> Intérêt pour ce cours pris en compte.
                                    </Text>
                                </HStack>
                            ) : (
                                <>
                                    <Button onPress={handleDismiss} variant="subtle2" size="sm" colorScheme="brown" startIcon={<Icon as={FontAwesome5} name="trash" />}>
                                        Pas intéressé
                                    </Button>
                                    <Button onPress={handleAccept} variant="solid" size="sm" colorScheme="pedagome" startIcon={<Icon as={FontAwesome5} name="thumbs-up" />}>
                                        Je suis intéressé
                                    </Button>
                                </>
                            )}
                        </HStack>
                        <Text fontWeight={600} textAlign="center">
                            Attention, cliquer sur "Je suis intéressé" ne garantit pas l'attribution de l'élève, ni d'être recontacté par l'équipe Pedagome.
                        </Text>
                    </ContentContainer>
                </ScrollView>
            )}

            <Modal isOpen={parrainageDialog.isOpen} onClose={() => parrainageDialog.hide()}>
                <Modal.Content>
                    {/*   <Modal.CloseButton /> */}
                    <Modal.Header>Parrainage</Modal.Header>
                    <Modal.Body>
                        <VStack>
                            <Text fontWeight={600}>Aide nous à trouver un tuteur pour cet élève et reçoit une prime de 10€ !</Text>
                            <Text mt={5}>Comment ça marche ?</Text>
                            <Text mt={5}>1. Une de tes connaissances est intéressée pour donnner des cours à cet élève</Text>
                            <Text mt={2}>2. Elle nous contacte pour un entretien et indique qu'elle vient de ta part</Text>
                            <Text mt={2}>3. L'entretien se passe bien et nous lui attribuons cet élève</Text>
                            <Text mt={2}>4. Nous te versons la prime de parrainage de 10€</Text>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button onPress={() => parrainageDialog.hide()} colorScheme="primary">
                                Ok
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </VStack>
    )
}

function ParrainageAlert() {
    return (
        <Alert /* status="info"  */ colorScheme="yellow" variant="subtle2">
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        <VStack>
                            <Heading>Parrainage</Heading>
                            <Text fontWeight={600} mt={5}>
                                Aide nous à trouver un tuteur pour cet élève et reçoit une prime de 10€ !
                            </Text>
                            <Text mt={5}>Comment ça marche ?</Text>
                            <Text>1. Une de tes connaissances est intéressées pour donnner des cours à cet élève</Text>
                            <Text>2. Elle nous contacte pour un entretien et indique qu'elle vient de ta part</Text>
                            <Text>3. L'entretien se passe bien et nous lui attribuons cet élève</Text>
                            <Text>4. Nous te versons la prime de parrainage de 10€</Text>
                        </VStack>
                    </HStack>
                </HStack>
            </VStack>
        </Alert>
    )
}

interface DetailSectionProps {
    title: string
    children: ReactNode | ReactNode[]
    hide?: boolean
    noDivider?: boolean
}
function DetailSection(props: DetailSectionProps) {
    const { children, title, hide, noDivider } = props
    const textColor = useColorModeValue("light.600", "dark.600")
    if (hide) return null
    return (
        <>
            {!noDivider && <Divider my="15px" />}

            <Text fontSize="xs" mb="3px" color={textColor}>
                {title}
            </Text>
            {children}
        </>
    )
}
