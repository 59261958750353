import { Address, Match, MatchInterest, MatchStatusEntries, rasmik, type ReadData, Teacher } from "@core/services/rasmik"
import { getShowedMonthStr, joinOu, txtmap } from "@core/utils"
import haversine from "haversine-distance"
import { find, min, uniq } from "lodash"

import type { UserInfoQueryData } from "./user_info"
import { defineQuery } from "./utils"

export const MAX_KM_DISTANCE = 30

const matchOffersReadOptions = rasmik.define.readOptions(Match).val({
    include: ["id", "label", "urgent", "status", "wishVisio", "broadcastOpenedAt"],
    children: {
        MatchInterests: true,
        Program: {
            include: ["id", "teachingLabel", "wishedFrequency", "wishedDurationMin"],
            children: {
                Learner: {
                    include: ["id"],
                    children: {
                        LessonAddresses: true,
                    },
                },
            },
        },
    },
})

const matchOfferDetailReadOptions = rasmik.define.readOptions(Match).val({
    include: ["id", "label", "urgent", "status", "wishVisio", "broadcastOpenedAt"],
    children: {
        MatchInterests: true,
        Program: {
            include: ["id", "teachingLabel", "wishedFrequency", "wishedDurationMin", "defaultTeacherInstructions", "initialMean", "goals"],
            children: {
                Learner: {
                    include: ["id"],
                    children: {
                        WeekDispos: true,
                        LessonAddresses: true,
                    },
                },
            },
        },
    },
})

export const match_offers_query = defineQuery("matchOffersQuery", ({ teacher }: { teacher: UserInfoQueryData | undefined }) => ({
    key: ["match_offers", { teacherId: teacher?.id, addresses: [teacher?.Addresses.map((addr) => addr.id)] }],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        const [offers, interests] = await Promise.all([
            rasmik
                .readMany(Match)
                .where({
                    status: [MatchStatusEntries.DRAFT, MatchStatusEntries.ON_HOLD],
                    broadcast: true,
                    broadcastOpenedAt: { $ne: null },
                    Teacher: null,
                    $or: [
                        { urgent: true },
                        {
                            Program: {
                                Teaching: {
                                    TeachingLevels: {
                                        Teachers: {
                                            id: teacher?.id,
                                        },
                                    },
                                },
                            },
                        },
                    ],
                })
                .options({
                    ...matchOffersReadOptions,
                    orderBy: {
                        broadcastOpenedAt: "desc",
                    },
                    limit: 50,
                })
                .run(),

            rasmik
                .readMany(MatchInterest)
                .where({
                    Teacher: auth?.userId!,
                    Match: {
                        status: [MatchStatusEntries.DRAFT, MatchStatusEntries.ON_HOLD],
                        broadcast: true,
                        broadcastOpenedAt: { $ne: null },
                        Teacher: null,
                    },
                })
                .run(),
        ])

        return offers
            .map((offer) => {
                const lessonAddressesPlus = offer.Program.Learner.LessonAddresses.map((laddr) => ({
                    ...laddr,
                    shortestDistanceKm: min(teacher?.Addresses.filter((addr) => !!addr.coordinates).map((taddr) => haversine(laddr.coordinates!, taddr.coordinates!) / 1000)) ?? null,
                }))

                const interest = interests.find((int) => int.Match === offer.id)
                return {
                    ...offer,
                    interestAnswer: interest?.teacherAnswer,
                    interestAnswerAt: interest?.teacherAnsweredAt,
                    lessonAddressesPlus,
                    shortestDistanceKm: min(lessonAddressesPlus.filter((addr) => addr.shortestDistanceKm !== null).map((addr) => addr.shortestDistanceKm)) ?? null,
                    placeText: getPlaceText(offer, lessonAddressesPlus),
                }
            })
            .filter((offer) => {
                return offer.interestAnswer !== "DISMISSED" && (offer.urgent || offer.wishVisio !== "Domicile" || (offer.shortestDistanceKm !== null && offer.shortestDistanceKm <= MAX_KM_DISTANCE))
            })
    },
    defaultOptions: { enabled: !!teacher, useErrorBoundary: true },
})).withHelpers((query) => ({
    findById(matchId: number | undefined) {
        return find(query.data ?? [], { id: matchId })
    },
}))

export type MatchOffersQueryData = (typeof match_offers_query)["dataShape"]
export type MatchOffersQueryItem = MatchOffersQueryData[number]

function getPlaceText(match: ReadData<Match, typeof matchOffersReadOptions>, addressesPlus: { shortestDistanceKm: number | null; cityName: string }[]) {
    // const cities = uniq(match.Program.Learner.LessonAddresses.map(addr => `${addr.cityName}`))
    // const placeText = `${txtmap(match.wishVisio, { Visio: "en visio", Domicile: `à ${joinOu(cities)}`, "Les deux": `à ${cities.join(', ')} ou en visio` })}`
    // return placeText

    const proximityAddresses = addressesPlus.filter((addr) => addr.shortestDistanceKm !== null && addr.shortestDistanceKm <= MAX_KM_DISTANCE)

    const cities = uniq(proximityAddresses.map((addr) => `${addr.cityName}`))
    const placeText = cities.length === 0 ? "en visio" : `${txtmap(match.wishVisio, { Visio: "en visio", Domicile: `À ${joinOu(cities)}`, "Les deux": `À ${cities.join(", ")} ou en visio` })}`
    return placeText
}

export const match_offer_detail_query = defineQuery("matchOfferDetailQuery", ({ teacher, matchId }: { teacher: UserInfoQueryData | undefined; matchId: number | null | undefined }) => ({
    key: ["match_offer_detail", { teacherId: teacher?.id, matchId }],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        const [offer, interest] = await Promise.all([
            rasmik
                .readOne(Match)
                .where({
                    id: matchId,
                    status: [MatchStatusEntries.DRAFT, MatchStatusEntries.ON_HOLD],
                    broadcast: true,
                    broadcastOpenedAt: { $ne: null },
                    Teacher: null,
                    // teacherAnswer: 'DISMISSED',
                })
                .options(matchOfferDetailReadOptions)
                .run(),
            rasmik
                .readOne(MatchInterest)
                .where({ Match: matchId, Teacher: auth?.userId! })
                .run(),
        ])

        const lessonAddressesPlus = offer.Program.Learner.LessonAddresses.map((laddr) => ({
            ...laddr,
            shortestDistanceKm: min(teacher?.Addresses.filter((addr) => !!addr.coordinates).map((taddr) => haversine(laddr.coordinates!, taddr.coordinates!) / 1000)) ?? null,
        }))

        return {
            ...offer,
            lessonAddressesPlus,
            interestAnswer: interest?.teacherAnswer,
            interestAnswerAt: interest?.teacherAnsweredAt,
            shortestDistanceKm: min(lessonAddressesPlus.filter((addr) => addr.shortestDistanceKm !== null).map((addr) => addr.shortestDistanceKm)) ?? null,
            placeText: getPlaceText(offer, lessonAddressesPlus),
        }
    },
    defaultOptions: { enabled: !!teacher && !!matchId, useErrorBoundary: true },
}))
